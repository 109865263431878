import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import MemberItem from '../components/MemberItem';
import TitlePage from '../components/TitlePage';
import LocalizedLink from '../components/LocalizedLink';
import useTranslations from '../components/useTranslations';

import * as S from '../components/ListWrapper/styled';

const Members = ({ data: { allMembersJson } }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const {
    hello,
    membersList,
    subline,
    category,
    latestPosts,
    allPosts,
  } = useTranslations();

  const memberList = allMembersJson.edges;

  return (
    <div className="homepage">
      <SEO title="Members List" />
      <TitlePage text={membersList} />
      <p>{subline}</p>
      <hr style={{ margin: `2rem 0` }} />      

      <S.ListWrapper>
        {memberList.map(
          ({
            node: {
              id,
              name,
              website,
              custom_field
            },
          }) => (
              <MemberItem
                id={id}
                name={name}
                website={website}
                name_chi={custom_field.cf_licensee_chi}
                tic_licno={custom_field.cf_license_no}
              />
            ),
        )}
      </S.ListWrapper>

    </div>
  );
};

export default Members;

export const query = graphql`
  query Members{
    allMembersJson{
      edges {
        node {
          id
          name
          website
          custom_field {
            cf_licensee_chi,
            cf_license_no
          }
        }
      }
    }
  }
`;
