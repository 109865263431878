import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const MemberItem = ({
  id,
  name,
  website,
  name_chi,
  tic_licno
}) => {

  //Add HTTP to URL without http prefix
  var prefix = 'http://';
  if (website && website.substr(0, prefix.length) !== prefix)
  {
      website = prefix + website;
  }

  return (
    <S.MemberItemLink href={website} title={name} target="_blank">
      <S.MemberItemWrapper>
        <S.MemberItemInfo>
          <S.MemberItemName>{name_chi}</S.MemberItemName>
          <S.MemberItemName>{name}</S.MemberItemName>
          <S.MemberItemDescription>License No: {tic_licno}</S.MemberItemDescription>
          <S.MemberItemDescription>{website}</S.MemberItemDescription>
        </S.MemberItemInfo>
      </S.MemberItemWrapper>
    </S.MemberItemLink>
  );
};

MemberItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  name_chi:PropTypes.string.isRequired
};

export default MemberItem;
